// Generated by purs version 0.14.5
"use strict";
var App_Foreign_UseContextSelector = require("../App.Foreign.UseContextSelector/index.js");
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var UseSelector = function (x) {
    return x;
};
var newtypeUseSelector = {
    Coercible0: function () {
        return undefined;
    }
};
var useSelector = function (dictEq) {
    return function (sc) {
        return function (selector) {
            return React_Basic_Hooks_Internal.coerceHook()(React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Foreign_UseContextSelector.useContextSelector(dictEq)(sc)(selector))(function (state) {
                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(state);
            }));
        };
    };
};
var mkStoreProvider = function (initialState) {
    return function (rootReducer) {
        return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (v) {
            return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)(Effect.monadEffect)(React_Basic_Hooks.mkReducer(rootReducer)))(function (reducer) {
                return AppComponent.appComponent("State")(function (children) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useReducer(initialState)(reducer))(function (v1) {
                        var stateProvider = React_Basic.provider(v.store.stateContext);
                        var dispatchProvider = React_Basic.provider(v.store.dispatchContext);
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(dispatchProvider(v1.value1)([ stateProvider(v1.value0)(children) ]));
                    });
                });
            });
        });
    };
};
module.exports = {
    mkStoreProvider: mkStoreProvider,
    useSelector: useSelector,
    UseSelector: UseSelector,
    newtypeUseSelector: newtypeUseSelector
};
