// Generated by purs version 0.14.5
"use strict";
var App_Components_Checkbox = require("../App.Components.Checkbox/index.js");
var App_Foreign_TextareaAutosize = require("../App.Foreign.TextareaAutosize/index.js");
var App_Foreign_UseClickOutside = require("../App.Foreign.UseClickOutside/index.js");
var App_Misc_Hook_UseDebouncedEffect = require("../App.Misc.Hook.UseDebouncedEffect/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkTodo = React_Basic_Hooks.memo(function __do() {
    var checkbox = App_Components_Checkbox.mkCheckbox();
    return React_Basic_Hooks.reactComponent()()()("Todo")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v1) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v2) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(v.todo.text))(function (v3) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(v.todo.note))(function (v4) {
                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Misc_Hook_UseDebouncedEffect.useDebounce(Data_Eq.eqString)(300)(v3.value0))(function (debouncedTodoText) {
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Misc_Hook_UseDebouncedEffect.useDebounce(Data_Eq.eqString)(300)(v4.value0))(function (debouncedTodoNote) {
                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Foreign_UseClickOutside.useClickOutside(v1.value1(function (v5) {
                                    return false;
                                })))(function (refClickOutside) {
                                    var isEditing = v1.value0 || v2.value0;
                                    var showNoteInput = isEditing || !Data_String_Common["null"](v4.value0);
                                    var handleTodoClick = React_Basic_DOM_Events.capture_(v1.value1(function (v5) {
                                        return true;
                                    }));
                                    var handleTextChange = React_Basic_DOM_Events.capture(React_Basic_DOM_Events.targetValue)(function (value) {
                                        return v3.value1(function (v5) {
                                            return Data_Maybe.fromMaybe("")(value);
                                        });
                                    });
                                    var handleOpenTodo = React_Basic_DOM_Events.capture_(v.onOpen(v.todo));
                                    var handleNoteChange = React_Basic_DOM_Events.capture(React_Basic_DOM_Events.targetValue)(function (value) {
                                        return v4.value1(function (v5) {
                                            return Data_Maybe.fromMaybe("")(value);
                                        });
                                    });
                                    var handleInputFocus = React_Basic_DOM_Events.capture_(v2.value1(function (v5) {
                                        return true;
                                    }));
                                    var handleInputBlur = React_Basic_DOM_Events.capture_(v2.value1(function (v5) {
                                        return false;
                                    }));
                                    var handleDeleteTodo = React_Basic_DOM_Events.capture_(v.onDelete(v.todo));
                                    var handleChangeStatus = function (updatedStatus) {
                                        return v.onChange({
                                            checked: updatedStatus,
                                            note: v.todo.note,
                                            text: v.todo.text,
                                            id: v.todo.id,
                                            children: v.todo.children,
                                            parent: v.todo.parent
                                        });
                                    };
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useMemo(Data_Tuple.eqTuple(Data_Eq.eqBoolean)(Data_Eq.eqString))(new Data_Tuple.Tuple(showNoteInput, v4.value0))(function (v5) {
                                        if (showNoteInput) {
                                            return React_Basic_DOM_Generated.div()({
                                                className: "pl-8 pr-6 pt-2",
                                                children: [ App_Foreign_TextareaAutosize.textareaAutosize()({
                                                    className: "w-full bg-transparent outline-black text-gray-600 text-sm resize-none",
                                                    type: "text",
                                                    placeholder: "Add notes...",
                                                    value: v4.value0,
                                                    onChange: handleNoteChange,
                                                    onFocus: handleInputFocus,
                                                    onBlur: handleInputBlur
                                                }) ]
                                            });
                                        };
                                        if (!showNoteInput) {
                                            return React_Basic_DOM_Generated.div_([  ]);
                                        };
                                        throw new Error("Failed pattern match at App.Components.Todo (line 80, column 51 - line 96, column 31): " + [ showNoteInput.constructor.name ]);
                                    }))(function (noteInput) {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useMemo(Data_Eq.eqBoolean)(isEditing)(function (v5) {
                                            if (isEditing) {
                                                return React_Basic_DOM_Generated.div()({
                                                    className: "pl-8 pt-2",
                                                    children: [ React_Basic_DOM_Generated.button()({
                                                        className: "flex flex-row text-sm text-rose-500",
                                                        onClick: handleDeleteTodo,
                                                        children: [ React_Basic_DOM_Generated.i()({
                                                            className: "gg-trash gg-small mr-1",
                                                            children: [  ]
                                                        }), React_Basic_DOM.text("Delete") ]
                                                    }) ]
                                                });
                                            };
                                            if (!isEditing) {
                                                return React_Basic_DOM_Generated.div_([  ]);
                                            };
                                            throw new Error("Failed pattern match at App.Components.Todo (line 101, column 37 - line 116, column 31): " + [ isEditing.constructor.name ]);
                                        }))(function (bottomBar) {
                                            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString))(new Data_Tuple.Tuple(debouncedTodoText, debouncedTodoNote))(function __do() {
                                                v.onChange({
                                                    checked: v.todo.checked,
                                                    note: debouncedTodoNote,
                                                    text: debouncedTodoText,
                                                    id: v.todo.id,
                                                    children: v.todo.children,
                                                    parent: v.todo.parent
                                                })();
                                                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                            }))(function () {
                                                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                                                    ref: refClickOutside,
                                                    onClick: handleTodoClick,
                                                    className: "bg-gray-100 py-3 px-5 rounded-lg",
                                                    children: [ React_Basic_DOM_Generated.div()({
                                                        className: "flex flex-row gap-x-3 items-center",
                                                        children: [ React_Basic_DOM_Generated.div()({
                                                            className: "basis-auto grow-0 shrink-0 flex items-center",
                                                            children: [ checkbox({
                                                                id: v.todo.id,
                                                                checked: v.todo.checked,
                                                                onChange: handleChangeStatus
                                                            }) ]
                                                        }), React_Basic_DOM_Generated.div()({
                                                            className: "basis-full grow shrink",
                                                            children: [ React_Basic_DOM_Generated.input()({
                                                                className: "w-full bg-transparent outline-black",
                                                                type: "text",
                                                                value: v3.value0,
                                                                onChange: handleTextChange,
                                                                onFocus: handleInputFocus,
                                                                onBlur: handleInputBlur
                                                            }) ]
                                                        }), React_Basic_DOM_Generated.div()({
                                                            className: "basis-auto grow-0 shrink-0 flex items-center",
                                                            children: [ React_Basic_DOM_Generated.button()({
                                                                onClick: handleOpenTodo,
                                                                children: [ React_Basic_DOM_Generated.i()({
                                                                    className: "gg-external gg-normal text-black-500",
                                                                    children: [  ]
                                                                }) ]
                                                            }) ]
                                                        }) ]
                                                    }), noteInput, bottomBar ]
                                                }));
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    })();
});
module.exports = {
    mkTodo: mkTodo
};
