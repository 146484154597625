// Generated by purs version 0.14.5
"use strict";
var App_Foreign_EnvConfig = require("../App.Foreign.EnvConfig/index.js");
var App_Routes = require("../App.Routes/index.js");
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Foreign = require("../Foreign/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Routing_PushState = require("../Routing.PushState/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useRouterContext = function (routerContext) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(routerContext))(function (maybeContextValue) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
            if (maybeContextValue instanceof Data_Maybe.Nothing) {
                return Partial_Unsafe.unsafeCrashWith("The Provider component for the Router context is missing");
            };
            if (maybeContextValue instanceof Data_Maybe.Just) {
                return maybeContextValue.value0;
            };
            throw new Error("Failed pattern match at App.Routes.Helpers (line 39, column 8 - line 41, column 38): " + [ maybeContextValue.constructor.name ]);
        })());
    });
};
var navigateTo = function (nav) {
    return function (route) {
        var rootDir = Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return "/" + v;
        })(App_Foreign_EnvConfig.getRootDir));
        var computedRoute = rootDir + route;
        return nav.pushState(Foreign.unsafeToForeign({}))(computedRoute);
    };
};
var mkRouterProvider = function (maybeRootDir) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (v) {
        return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect))(Routing_PushState.makeInterface))(function (nav) {
            return AppComponent.appComponent("Router")(function (children) {
                var routerProvider = React_Basic.provider(v.router.routerContext);
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks["useState'"](new Data_Maybe.Just(App_Routes.RootTodos.value)))(function (v1) {
                    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffectOnce(Routing_PushState.matches(App_Routes.mkAppRoute(maybeRootDir))(function (v2) {
                        return function (newRoute) {
                            return v1.value1(newRoute);
                        };
                    })(nav)))(function () {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(routerProvider(new Data_Maybe.Just({
                            nav: nav,
                            route: v1.value0
                        }))(children));
                    });
                });
            });
        });
    });
};
var mkRouterContext = React_Basic.createContext(Data_Maybe.Nothing.value);
var mkRouter = function __do() {
    var routerContext = mkRouterContext();
    return {
        routerContext: routerContext
    };
};
module.exports = {
    mkRouterContext: mkRouterContext,
    mkRouter: mkRouter,
    useRouterContext: useRouterContext,
    mkRouterProvider: mkRouterProvider,
    navigateTo: navigateTo
};
