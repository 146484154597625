// Generated by purs version 0.14.5
"use strict";
var App_Components_Layout = require("../App.Components.Layout/index.js");
var App_Components_Link = require("../App.Components.Link/index.js");
var App_Foreign_Images = require("../App.Foreign.Images/index.js");
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkNavbar = Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)(Effect.monadEffect)(App_Components_Layout.mkLayout))(function (layout) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(App_Components_Link.mkLink)(function (link) {
        return AppComponent.appComponent("Navbar")(function (v) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                className: "pt-20",
                children: [ layout([ React_Basic_DOM_Generated.div()({
                    className: "flex flex-row justify-between items-center",
                    children: [ React_Basic_DOM_Generated.div_([ link({
                        route: "/",
                        children: [ React_Basic_DOM_Generated.span()({
                            className: "flex items-center",
                            children: [ React_Basic_DOM_Generated.img()({
                                className: "h-6 md:h-8 lg:h-10",
                                src: Data_Maybe.fromMaybe("")(App_Foreign_Images.getLogoImage),
                                alt: "Logo"
                            }), React_Basic_DOM_Generated.span()({
                                className: "text-2xl md:text-3xl lg:text-5xl tracking-wide font-black text-black pl-4",
                                children: [ React_Basic_DOM.text("inflist") ]
                            }) ]
                        }) ]
                    }) ]), React_Basic_DOM_Generated.div_([ React_Basic_DOM_Generated.a()({
                        className: "text-black flex items-center",
                        href: "https://github.com/dgopsq/inflist",
                        target: "_blank",
                        rel: "noopener",
                        children: [ React_Basic_DOM_Generated.i()({
                            className: "gg-code-slash gg-small mr-4",
                            children: [  ]
                        }), React_Basic_DOM.text("GitHub") ]
                    }) ]) ]
                }) ]) ]
            }));
        });
    });
});
module.exports = {
    mkNavbar: mkNavbar
};
