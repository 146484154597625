// Generated by purs version 0.14.5
"use strict";
var App_State_Todo = require("../App.State.Todo/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_List = require("../Data.List/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var AddTodo = (function () {
    function AddTodo(value0) {
        this.value0 = value0;
    };
    AddTodo.create = function (value0) {
        return new AddTodo(value0);
    };
    return AddTodo;
})();
var UpdateTodo = (function () {
    function UpdateTodo(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    UpdateTodo.create = function (value0) {
        return function (value1) {
            return new UpdateTodo(value0, value1);
        };
    };
    return UpdateTodo;
})();
var LoadTodo = (function () {
    function LoadTodo(value0) {
        this.value0 = value0;
    };
    LoadTodo.create = function (value0) {
        return new LoadTodo(value0);
    };
    return LoadTodo;
})();
var DeleteTodo = (function () {
    function DeleteTodo(value0) {
        this.value0 = value0;
    };
    DeleteTodo.create = function (value0) {
        return new DeleteTodo(value0);
    };
    return DeleteTodo;
})();
var todosMapReducer = function (state) {
    return function (v) {
        if (v instanceof AddTodo) {
            if (Data_String_CodePoints.length(v.value0.text) > 0) {
                var stateWithAddedTodo = Data_Map_Internal.insert(Data_Ord.ordString)(v.value0.id)(v.value0)(state);
                var maybeParent = Data_Map_Internal.lookup(Data_Ord.ordString)(v.value0.parent)(state);
                if (maybeParent instanceof Data_Maybe.Just) {
                    return Data_Map_Internal.insert(Data_Ord.ordString)(maybeParent.value0.id)({
                        children: Data_List.snoc(maybeParent.value0.children)(v.value0.id),
                        checked: maybeParent.value0.checked,
                        id: maybeParent.value0.id,
                        note: maybeParent.value0.note,
                        parent: maybeParent.value0.parent,
                        text: maybeParent.value0.text
                    })(stateWithAddedTodo);
                };
                return state;
            };
            if (Data_Boolean.otherwise) {
                return state;
            };
        };
        if (v instanceof UpdateTodo) {
            var maybeTodo = Data_Map_Internal.lookup(Data_Ord.ordString)(v.value0)(state);
            if (maybeTodo instanceof Data_Maybe.Just) {
                return Data_Map_Internal.insert(Data_Ord.ordString)(v.value0)(v.value1)(state);
            };
            if (maybeTodo instanceof Data_Maybe.Nothing) {
                return state;
            };
            throw new Error("Failed pattern match at App.State.TodosMapReducer (line 59, column 53 - line 61, column 19): " + [ maybeTodo.constructor.name ]);
        };
        if (v instanceof LoadTodo) {
            return Data_Map_Internal.insert(Data_Ord.ordString)(v.value0.id)(v.value0)(state);
        };
        if (v instanceof DeleteTodo) {
            var stateWithDeletedTodo = Data_Map_Internal["delete"](Data_Ord.ordString)(v.value0.id)(state);
            var maybeParent = Data_Map_Internal.lookup(Data_Ord.ordString)(v.value0.parent)(state);
            if (maybeParent instanceof Data_Maybe.Just) {
                return Data_Map_Internal.insert(Data_Ord.ordString)(maybeParent.value0.id)({
                    children: Data_List["delete"](Data_Eq.eqString)(v.value0.id)(maybeParent.value0.children),
                    checked: maybeParent.value0.checked,
                    id: maybeParent.value0.id,
                    note: maybeParent.value0.note,
                    parent: maybeParent.value0.parent,
                    text: maybeParent.value0.text
                })(stateWithDeletedTodo);
            };
            return state;
        };
        throw new Error("Failed pattern match at App.State.TodosMapReducer (line 42, column 1 - line 42, column 68): " + [ state.constructor.name, v.constructor.name ]);
    };
};
var todosMapInitialState = Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)([ App_State_Todo.rootTodoTuple ]);
var injAction = Data_Variant.inj()({
    reflectSymbol: function () {
        return "todosMap";
    }
})(Type_Proxy["Proxy"].value);
var loadTodo = function ($16) {
    return injAction(LoadTodo.create($16));
};
var updateTodo = function (todoId) {
    return function (newTodo) {
        return injAction(new UpdateTodo(todoId, newTodo));
    };
};
var deleteTodo = function ($17) {
    return injAction(DeleteTodo.create($17));
};
var addTodo = function ($18) {
    return injAction(AddTodo.create($18));
};
module.exports = {
    AddTodo: AddTodo,
    UpdateTodo: UpdateTodo,
    LoadTodo: LoadTodo,
    DeleteTodo: DeleteTodo,
    todosMapInitialState: todosMapInitialState,
    todosMapReducer: todosMapReducer,
    injAction: injAction,
    addTodo: addTodo,
    updateTodo: updateTodo,
    loadTodo: loadTodo,
    deleteTodo: deleteTodo
};
