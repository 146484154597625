// Generated by purs version 0.14.5
"use strict";
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkNotFoundPage = AppComponent.appComponent("NotFoundPage")(function (v) {
    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div_([ React_Basic_DOM_Generated.h1_([ React_Basic_DOM.text("404 Not Found") ]) ]));
});
module.exports = {
    mkNotFoundPage: mkNotFoundPage
};
