// Generated by purs version 0.14.5
"use strict";
var App_Foreign_UseContextSelector = require("../App.Foreign.UseContextSelector/index.js");
var App_State_RootReducer = require("../App.State.RootReducer/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var mkStateContext = App_Foreign_UseContextSelector.createContextSelector(App_State_RootReducer.rootInitialState);
var mkDispatchContext = React_Basic.createContext(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
});
var mkStore = function __do() {
    var stateContext = mkStateContext();
    var dispatchContext = mkDispatchContext();
    return {
        stateContext: stateContext,
        dispatchContext: dispatchContext
    };
};
module.exports = {
    mkStateContext: mkStateContext,
    mkDispatchContext: mkDispatchContext,
    mkStore: mkStore
};
