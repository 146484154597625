// Generated by purs version 0.14.5
"use strict";
var App_Api_Storage_LocalStorage = require("../App.Api.Storage.LocalStorage/index.js");
var App_Foreign_EnvConfig = require("../App.Foreign.EnvConfig/index.js");
var App_Pages_NotFoundPage = require("../App.Pages.NotFoundPage/index.js");
var App_Pages_TodosListPage = require("../App.Pages.TodosListPage/index.js");
var App_Routes = require("../App.Routes/index.js");
var App_Routes_Helpers = require("../App.Routes.Helpers/index.js");
var App_State_Helpers = require("../App.State.Helpers/index.js");
var App_State_RootReducer = require("../App.State.RootReducer/index.js");
var App_State_Store = require("../App.State.Store/index.js");
var App_State_Todo = require("../App.State.Todo/index.js");
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Web_DOM_NonElementParentNode = require("../Web.DOM.NonElementParentNode/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_HTMLDocument = require("../Web.HTML.HTMLDocument/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var mkApp = Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (v) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(App_Pages_TodosListPage.mkTodosListPage)(function (todosListPage) {
        return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(App_Pages_NotFoundPage.mkNotFoundPage)(function (notFoundPage) {
            return AppComponent.appComponent("App")(function (v1) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Routes_Helpers.useRouterContext(v.router.routerContext))(function (v2) {
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.fragment([ (function () {
                        if (v2.route instanceof Data_Maybe.Just && v2.route.value0 instanceof App_Routes.RootTodos) {
                            return todosListPage({
                                parentId: App_State_Todo.rootTodoId
                            });
                        };
                        if (v2.route instanceof Data_Maybe.Just && v2.route.value0 instanceof App_Routes.ChildrenTodos) {
                            return todosListPage({
                                parentId: v2.route.value0.value0
                            });
                        };
                        if (v2.route instanceof Data_Maybe.Nothing) {
                            return notFoundPage(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at Main (line 62, column 11 - line 65, column 41): " + [ v2.route.constructor.name ]);
                    })() ]));
                });
            });
        });
    });
});
var main = function __do() {
    var root = Control_Bind.bindFlipped(Effect.bindEffect)(Web_DOM_NonElementParentNode.getElementById("root"))(Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toNonElementParentNode)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window)))();
    if (root instanceof Data_Maybe.Nothing) {
        return Effect_Exception["throw"]("Root element not found.")();
    };
    if (root instanceof Data_Maybe.Just) {
        var router = App_Routes_Helpers.mkRouter();
        var store = App_State_Store.mkStore();
        var env = {
            router: router,
            store: store,
            todosStorage: App_Api_Storage_LocalStorage.localTodosStorage
        };
        var routerProvider = Control_Monad_Reader_Trans.runReaderT(App_Routes_Helpers.mkRouterProvider(App_Foreign_EnvConfig.getRootDir))(env)();
        var storeProvider = Control_Monad_Reader_Trans.runReaderT(App_State_Helpers.mkStoreProvider(App_State_RootReducer.rootInitialState)(App_State_RootReducer.rootReducer))(env)();
        var app = Control_Monad_Reader_Trans.runReaderT(mkApp)(env)();
        return React_Basic_DOM.render(routerProvider([ storeProvider([ app(Data_Unit.unit) ]) ]))(root.value0)();
    };
    throw new Error("Failed pattern match at Main (line 36, column 3 - line 48, column 63): " + [ root.constructor.name ]);
};
module.exports = {
    main: main,
    mkApp: mkApp
};
