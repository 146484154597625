// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var useClickOutside = function (callback) {
    return React_Basic_Hooks_Internal.unsafeHook(function () {
        return $foreign.useClickOutside_(callback);
    });
};
module.exports = {
    useClickOutside: useClickOutside,
    useClickOutside_: $foreign.useClickOutside_
};
