// Generated by purs version 0.14.5
"use strict";
var App_Foreign_TextareaAutosize = require("../App.Foreign.TextareaAutosize/index.js");
var App_Misc_Hook_UseDebouncedEffect = require("../App.Misc.Hook.UseDebouncedEffect/index.js");
var App_Misc_Hook_UsePrev = require("../App.Misc.Hook.UsePrev/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkSingleTodoHeader = React_Basic_Hooks.component("SingleTodoHeader")(function (v) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(v.todo.text))(function (v1) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(v.todo.note))(function (v2) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Misc_Hook_UseDebouncedEffect.useDebounce(Data_Eq.eqString)(300)(v1.value0))(function (debouncedTodoText) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Misc_Hook_UseDebouncedEffect.useDebounce(Data_Eq.eqString)(300)(v2.value0))(function (debouncedTodoNote) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Misc_Hook_UsePrev.usePrev(Data_Eq.eqString)(v.todo.id))(function (maybePrevTodoId) {
                        var handleTextChange = React_Basic_DOM_Events.capture(React_Basic_DOM_Events.targetValue)(function (value) {
                            return v1.value1(function (v3) {
                                return Data_Maybe.fromMaybe("")(value);
                            });
                        });
                        var handleNoteChange = React_Basic_DOM_Events.capture(React_Basic_DOM_Events.targetValue)(function (value) {
                            return v2.value1(function (v3) {
                                return Data_Maybe.fromMaybe("")(value);
                            });
                        });
                        return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString))(new Data_Tuple.Tuple(debouncedTodoText, debouncedTodoNote))(function __do() {
                            v.onChange({
                                text: debouncedTodoText,
                                note: debouncedTodoNote,
                                id: v.todo.id,
                                checked: v.todo.checked,
                                children: v.todo.children,
                                parent: v.todo.parent
                            })();
                            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                        }))(function () {
                            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Tuple.eqTuple(Data_Maybe.eqMaybe(Data_Eq.eqString))(Data_Eq.eqString))(new Data_Tuple.Tuple(maybePrevTodoId, v.todo.id))((function () {
                                var $11 = Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Eq.eqString))(maybePrevTodoId)(new Data_Maybe.Just(v.todo.id));
                                if ($11) {
                                    return function __do() {
                                        v1.value1(function (v3) {
                                            return v.todo.text;
                                        })();
                                        v2.value1(function (v3) {
                                            return v.todo.note;
                                        })();
                                        return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                    };
                                };
                                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
                            })()))(function () {
                                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div_([ React_Basic_DOM_Generated.input()({
                                    className: "text-xl font-bold w-full outline-black",
                                    value: v1.value0,
                                    onChange: handleTextChange
                                }), React_Basic_DOM_Generated.div()({
                                    className: "mt-2",
                                    children: [ App_Foreign_TextareaAutosize.textareaAutosize()({
                                        className: "w-full bg-transparent outline-black resize-none",
                                        value: v2.value0,
                                        onChange: handleNoteChange,
                                        placeholder: "Add notes..."
                                    }) ]
                                }) ]));
                            });
                        });
                    });
                });
            });
        });
    });
});
module.exports = {
    mkSingleTodoHeader: mkSingleTodoHeader
};
