// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_NonEmpty = require("../Data.List.NonEmpty/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Effect = require("../Effect/index.js");
var Foreign = require("../Foreign/index.js");
var UUID = function (x) {
    return x;
};
var toString = function (v) {
    return v;
};
var showUUID = {
    show: function (v) {
        return "(UUID " + (v + ")");
    }
};
var parseUUID = function (str) {
    var v = $foreign.validateV4UUID(str);
    if (v) {
        return Data_Maybe.Just.create(str);
    };
    return Data_Maybe.Nothing.value;
};
var genv5UUID = function (s) {
    return function (v) {
        return $foreign.getUUID5Impl(s)(v);
    };
};
var genv3UUID = function (s) {
    return function (v) {
        return UUID($foreign.getUUID3Impl(s)(v));
    };
};
var genUUID = Control_Bind.bind(Effect.bindEffect)($foreign.getUUIDImpl)((function () {
    var $24 = Control_Applicative.pure(Effect.applicativeEffect);
    return function ($25) {
        return $24(UUID($25));
    };
})());
var eqUUID = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordUUID = {
    compare: function (x) {
        return function (y) {
            return Data_Ord.compare(Data_Ord.ordString)(x)(y);
        };
    },
    Eq0: function () {
        return eqUUID;
    }
};
var encodeUUID = {
    encode: function ($26) {
        return Foreign.unsafeToForeign(toString($26));
    }
};
var emptyUUID = "00000000-0000-0000-0000-000000000000";
var decodeUUID = {
    decode: function (x) {
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Foreign.readString(Data_Identity.monadIdentity)(x))((function () {
            var $27 = Control_Monad_Except_Trans.except(Data_Identity.applicativeIdentity);
            var $28 = Data_Either.note(Data_List_NonEmpty.singleton(new Foreign.ForeignError("Failed to parse foreign UUID")));
            return function ($29) {
                return $27($28(parseUUID($29)));
            };
        })());
    }
};
module.exports = {
    emptyUUID: emptyUUID,
    genUUID: genUUID,
    parseUUID: parseUUID,
    genv3UUID: genv3UUID,
    genv5UUID: genv5UUID,
    toString: toString,
    showUUID: showUUID,
    eqUUID: eqUUID,
    ordUUID: ordUUID,
    decodeUUID: decodeUUID,
    encodeUUID: encodeUUID
};
