// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var useContextSelector = function (dictEq) {
    return function (context) {
        return function (selector) {
            return React_Basic_Hooks_Internal.unsafeHook(function () {
                return $foreign.useContextSelector_(context, selector);
            });
        };
    };
};
module.exports = {
    useContextSelector: useContextSelector,
    useContextSelector_: $foreign.useContextSelector_,
    createContextSelector: $foreign.createContextSelector
};
