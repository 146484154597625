// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_ST_Global = require("../Control.Monad.ST.Global/index.js");
var monadSTST = {
    liftST: Control_Category.identity(Control_Category.categoryFn)
};
var monadSTEffect = {
    liftST: Control_Monad_ST_Global.toEffect
};
var liftST = function (dict) {
    return dict.liftST;
};
module.exports = {
    liftST: liftST,
    monadSTEffect: monadSTEffect,
    monadSTST: monadSTST
};
