// Generated by purs version 0.14.5
"use strict";
var App_Components_AddTodoInput = require("../App.Components.AddTodoInput/index.js");
var App_Components_Breadcrumb = require("../App.Components.Breadcrumb/index.js");
var App_Components_Layout = require("../App.Components.Layout/index.js");
var App_Components_Navbar = require("../App.Components.Navbar/index.js");
var App_Components_SingleTodoHeader = require("../App.Components.SingleTodoHeader/index.js");
var App_Components_TodosList = require("../App.Components.TodosList/index.js");
var App_Misc_Hook_UsePrev = require("../App.Misc.Hook.UsePrev/index.js");
var App_State_Helpers = require("../App.State.Helpers/index.js");
var App_State_Selectors = require("../App.State.Selectors/index.js");
var App_State_Todo = require("../App.State.Todo/index.js");
var App_State_TodosMapReducer = require("../App.State.TodosMapReducer/index.js");
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Aff = require("../React.Basic.Hooks.Aff/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkTodosListPage = Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (v) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(App_Components_TodosList.mkTodosList)(function (todosList) {
        return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect))(App_Components_AddTodoInput.mkAddTodoInput))(function (addTodoInput) {
            return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)(Effect.monadEffect)(App_Components_Layout.mkLayout))(function (layout) {
                return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(App_Components_Navbar.mkNavbar)(function (navbar) {
                    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(App_Components_Breadcrumb.mkBreadcrumb)(function (breadcrumb) {
                        return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)(Effect.monadEffect)(App_Components_SingleTodoHeader.mkSingleTodoHeader))(function (singleTodoHeader) {
                            return AppComponent.appComponent("TodosListPage")(function (v1) {
                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_State_Helpers.useSelector(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                                    reflectSymbol: function () {
                                        return "todosMap";
                                    }
                                })(Data_Map_Internal.eqMap(Data_Eq.eqString)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                                    reflectSymbol: function () {
                                        return "text";
                                    }
                                })(Data_Eq.eqString))()({
                                    reflectSymbol: function () {
                                        return "parent";
                                    }
                                })(Data_Eq.eqString))()({
                                    reflectSymbol: function () {
                                        return "note";
                                    }
                                })(Data_Eq.eqString))()({
                                    reflectSymbol: function () {
                                        return "id";
                                    }
                                })(Data_Eq.eqString))()({
                                    reflectSymbol: function () {
                                        return "children";
                                    }
                                })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                                    reflectSymbol: function () {
                                        return "checked";
                                    }
                                })(Data_Eq.eqBoolean))))))(v.store.stateContext)(App_State_Selectors.todosMapSelector))(function (todosMapState) {
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(v.store.dispatchContext))(function (dispatch) {
                                        var maybeParent = Data_Map_Internal.lookup(Data_Ord.ordString)(v1.parentId)(todosMapState);
                                        var renderBreadcrumb = (function () {
                                            var v2 = new Data_Tuple.Tuple(v1.parentId !== App_State_Todo.rootTodoId, maybeParent);
                                            if (v2.value0 && v2.value1 instanceof Data_Maybe.Just) {
                                                return breadcrumb({
                                                    currentTodo: v2.value1.value0
                                                });
                                            };
                                            return React_Basic_DOM_Generated.div_([  ]);
                                        })();
                                        var showedTodos = Data_Maybe.fromMaybe(Data_List_Types.Nil.value)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                                            return v2.children;
                                        })(maybeParent));
                                        var handleUpdate = function (updatedTodo) {
                                            return dispatch(App_State_TodosMapReducer.updateTodo(updatedTodo.id)(updatedTodo));
                                        };
                                        var renderHeader = (function () {
                                            if (maybeParent instanceof Data_Maybe.Just) {
                                                var $12 = maybeParent.value0.id !== App_State_Todo.rootTodoId;
                                                if ($12) {
                                                    return singleTodoHeader({
                                                        todo: maybeParent.value0,
                                                        onChange: handleUpdate
                                                    });
                                                };
                                                return React_Basic_DOM_Generated.div_([  ]);
                                            };
                                            if (maybeParent instanceof Data_Maybe.Nothing) {
                                                return React_Basic_DOM_Generated.div_([  ]);
                                            };
                                            throw new Error("Failed pattern match at App.Pages.TodosListPage (line 65, column 22 - line 71, column 31): " + [ maybeParent.constructor.name ]);
                                        })();
                                        var handleAdd = function (text) {
                                            return function __do() {
                                                var newTodo = App_State_Todo.genUniqTodo(v1.parentId)(text)(false)();
                                                return dispatch(App_State_TodosMapReducer.addTodo(newTodo))();
                                            };
                                        };
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Data_Functor.map(React_Basic_Hooks_Internal.functorRender)(Data_Maybe.fromMaybe(Data_Maybe.Nothing.value))(App_Misc_Hook_UsePrev.usePrev(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                                            reflectSymbol: function () {
                                                return "text";
                                            }
                                        })(Data_Eq.eqString))()({
                                            reflectSymbol: function () {
                                                return "parent";
                                            }
                                        })(Data_Eq.eqString))()({
                                            reflectSymbol: function () {
                                                return "note";
                                            }
                                        })(Data_Eq.eqString))()({
                                            reflectSymbol: function () {
                                                return "id";
                                            }
                                        })(Data_Eq.eqString))()({
                                            reflectSymbol: function () {
                                                return "children";
                                            }
                                        })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                                            reflectSymbol: function () {
                                                return "checked";
                                            }
                                        })(Data_Eq.eqBoolean))))(maybeParent)))(function (prevMaybeParent) {
                                            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Aff.useAff(Data_Eq.eqString)(v1.parentId)(Control_Bind.bind(Effect_Aff.bindAff)(v.todosStorage.retrieve(v1.parentId))(function (eitherRetrievedParentTodo) {
                                                if (eitherRetrievedParentTodo instanceof Data_Either.Right && eitherRetrievedParentTodo.value0 instanceof Data_Maybe.Just) {
                                                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(dispatch(App_State_TodosMapReducer.loadTodo(eitherRetrievedParentTodo.value0.value0)));
                                                };
                                                return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                                            })))(function () {
                                                return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Aff.useAff(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                                                    reflectSymbol: function () {
                                                        return "text";
                                                    }
                                                })(Data_Eq.eqString))()({
                                                    reflectSymbol: function () {
                                                        return "parent";
                                                    }
                                                })(Data_Eq.eqString))()({
                                                    reflectSymbol: function () {
                                                        return "note";
                                                    }
                                                })(Data_Eq.eqString))()({
                                                    reflectSymbol: function () {
                                                        return "id";
                                                    }
                                                })(Data_Eq.eqString))()({
                                                    reflectSymbol: function () {
                                                        return "children";
                                                    }
                                                })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                                                    reflectSymbol: function () {
                                                        return "checked";
                                                    }
                                                })(Data_Eq.eqBoolean))))(maybeParent)(Control_Bind.bind(Effect_Aff.bindAff)((function () {
                                                    if (maybeParent instanceof Data_Maybe.Just) {
                                                        return Control_Bind.bind(Effect_Aff.bindAff)(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.isJust(Data_Map_Internal.lookup(Data_Ord.ordString)(maybeParent.value0.parent)(todosMapState))))(function (isPreviousLoaded) {
                                                            if (!isPreviousLoaded) {
                                                                return v.todosStorage.retrieve(maybeParent.value0.parent);
                                                            };
                                                            if (isPreviousLoaded) {
                                                                return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left("Previous todo already loaded"));
                                                            };
                                                            throw new Error("Failed pattern match at App.Pages.TodosListPage (line 92, column 11 - line 94, column 63): " + [ isPreviousLoaded.constructor.name ]);
                                                        });
                                                    };
                                                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left("Parent todo do not exists"));
                                                })())(function (eitherRetrievedPreviousTodo) {
                                                    if (eitherRetrievedPreviousTodo instanceof Data_Either.Right && eitherRetrievedPreviousTodo.value0 instanceof Data_Maybe.Just) {
                                                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(dispatch(App_State_TodosMapReducer.loadTodo(eitherRetrievedPreviousTodo.value0.value0)));
                                                    };
                                                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                                                })))(function () {
                                                    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Aff.useAff(Data_Tuple.eqTuple(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                                                        reflectSymbol: function () {
                                                            return "text";
                                                        }
                                                    })(Data_Eq.eqString))()({
                                                        reflectSymbol: function () {
                                                            return "parent";
                                                        }
                                                    })(Data_Eq.eqString))()({
                                                        reflectSymbol: function () {
                                                            return "note";
                                                        }
                                                    })(Data_Eq.eqString))()({
                                                        reflectSymbol: function () {
                                                            return "id";
                                                        }
                                                    })(Data_Eq.eqString))()({
                                                        reflectSymbol: function () {
                                                            return "children";
                                                        }
                                                    })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                                                        reflectSymbol: function () {
                                                            return "checked";
                                                        }
                                                    })(Data_Eq.eqBoolean))))(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                                                        reflectSymbol: function () {
                                                            return "text";
                                                        }
                                                    })(Data_Eq.eqString))()({
                                                        reflectSymbol: function () {
                                                            return "parent";
                                                        }
                                                    })(Data_Eq.eqString))()({
                                                        reflectSymbol: function () {
                                                            return "note";
                                                        }
                                                    })(Data_Eq.eqString))()({
                                                        reflectSymbol: function () {
                                                            return "id";
                                                        }
                                                    })(Data_Eq.eqString))()({
                                                        reflectSymbol: function () {
                                                            return "children";
                                                        }
                                                    })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                                                        reflectSymbol: function () {
                                                            return "checked";
                                                        }
                                                    })(Data_Eq.eqBoolean)))))(new Data_Tuple.Tuple(prevMaybeParent, maybeParent))((function () {
                                                        var v2 = new Data_Tuple.Tuple(prevMaybeParent, maybeParent);
                                                        if (v2.value0 instanceof Data_Maybe.Just && v2.value1 instanceof Data_Maybe.Just) {
                                                            var $25 = Data_Eq.notEq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                                                                reflectSymbol: function () {
                                                                    return "text";
                                                                }
                                                            })(Data_Eq.eqString))()({
                                                                reflectSymbol: function () {
                                                                    return "parent";
                                                                }
                                                            })(Data_Eq.eqString))()({
                                                                reflectSymbol: function () {
                                                                    return "note";
                                                                }
                                                            })(Data_Eq.eqString))()({
                                                                reflectSymbol: function () {
                                                                    return "id";
                                                                }
                                                            })(Data_Eq.eqString))()({
                                                                reflectSymbol: function () {
                                                                    return "children";
                                                                }
                                                            })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                                                                reflectSymbol: function () {
                                                                    return "checked";
                                                                }
                                                            })(Data_Eq.eqBoolean)))(v2.value0.value0)(v2.value1.value0);
                                                            if ($25) {
                                                                return v.todosStorage.store(v2.value1.value0.id)(v2.value1.value0);
                                                            };
                                                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Right(Data_Unit.unit));
                                                        };
                                                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Right(Data_Unit.unit));
                                                    })()))(function () {
                                                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div_([ navbar({
                                                            parentId: v1.parentId
                                                        }), layout([ React_Basic_DOM_Generated.div()({
                                                            className: "pt-10",
                                                            children: [ React_Basic_DOM_Generated.div()({
                                                                className: "",
                                                                children: [ renderBreadcrumb ]
                                                            }), React_Basic_DOM_Generated.div()({
                                                                className: "mt-6",
                                                                children: [ renderHeader ]
                                                            }), React_Basic_DOM_Generated.div()({
                                                                className: "mt-8",
                                                                children: [ React_Basic.element(addTodoInput)({
                                                                    onAdd: handleAdd
                                                                }) ]
                                                            }), React_Basic_DOM_Generated.div()({
                                                                className: (function () {
                                                                    var $30 = Data_List.length(showedTodos) > 0;
                                                                    if ($30) {
                                                                        return "mt-4";
                                                                    };
                                                                    return "";
                                                                })(),
                                                                children: [ todosList({
                                                                    todos: showedTodos
                                                                }) ]
                                                            }) ]
                                                        }) ]) ]));
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
});
module.exports = {
    mkTodosListPage: mkTodosListPage
};
