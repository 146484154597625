// Generated by purs version 0.14.5
"use strict";
var App_Routes_Helpers = require("../App.Routes.Helpers/index.js");
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkLink = Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (v) {
    return AppComponent.appComponent("Link")(function (v1) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Routes_Helpers.useRouterContext(v.router.routerContext))(function (v2) {
            var handlePress = React_Basic_DOM_Events.capture_(App_Routes_Helpers.navigateTo(v2.nav)(v1.route));
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.a()({
                href: "#",
                onClick: handlePress,
                children: v1.children
            }));
        });
    });
});
module.exports = {
    mkLink: mkLink
};
