// Generated by purs version 0.14.5
"use strict";
var App_Misc_Codecs = require("../App.Misc.Codecs/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Decode_Parser = require("../Data.Argonaut.Decode.Parser/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var Web_Storage_Storage = require("../Web.Storage.Storage/index.js");
var localStorageStore = function (key) {
    return function (value) {
        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
            var w = Web_HTML.window();
            var s = Web_HTML_Window.localStorage(w)();
            return Web_Storage_Storage.setItem(key)(value)(s)();
        });
    };
};
var store = function (todoId) {
    return function (todo) {
        var encodedtodo = Data_Argonaut_Core.stringify(App_Misc_Codecs.todoToJson(todo));
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Console.log("Stored todo " + Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "checked";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "children";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "note";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "parent";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "text";
            }
        })(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString))(Data_List_Types.showList(Data_Show.showString)))(Data_Show.showBoolean)))(todo))))(function () {
            return Control_Bind.bind(Effect_Aff.bindAff)(localStorageStore(todoId)(encodedtodo))(function () {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Right(Data_Unit.unit));
            });
        });
    };
};
var localStorageRetrieve = function (key) {
    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
        var w = Web_HTML.window();
        var s = Web_HTML_Window.localStorage(w)();
        return Web_Storage_Storage.getItem(key)(s)();
    });
};
var retrieve = function (todoId) {
    return Control_Bind.bind(Effect_Aff.bindAff)(localStorageRetrieve(todoId))(function (maybeRetrieved) {
        return Control_Bind.bind(Effect_Aff.bindAff)((function () {
            if (maybeRetrieved instanceof Data_Maybe.Just) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Either.hush(Control_Bind.bindFlipped(Data_Either.bindEither)(App_Misc_Codecs.todoFromJson)(Data_Argonaut_Decode_Parser.parseJson(maybeRetrieved.value0)))))(function (parsed) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Console.log("Retrieved todo " + Data_Show.show(Data_Maybe.showMaybe(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "checked";
                        }
                    })(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "children";
                        }
                    })(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "id";
                        }
                    })(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "note";
                        }
                    })(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "parent";
                        }
                    })(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "text";
                        }
                    })(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString))(Data_List_Types.showList(Data_Show.showString)))(Data_Show.showBoolean))))(parsed))))(function () {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(parsed);
                    });
                });
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
        })())(function (result) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Right(result));
        });
    });
};
var localStorageDelete = function (key) {
    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
        var w = Web_HTML.window();
        var s = Web_HTML_Window.localStorage(w)();
        return Web_Storage_Storage.removeItem(key)(s)();
    });
};
var $$delete = function (todoId) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Console.log("Deleted todo " + todoId)))(function () {
        return Control_Bind.bind(Effect_Aff.bindAff)(localStorageDelete(todoId))(function () {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Right(Data_Unit.unit));
        });
    });
};
var localTodosStorage = {
    store: store,
    retrieve: retrieve,
    "delete": $$delete
};
module.exports = {
    localTodosStorage: localTodosStorage
};
