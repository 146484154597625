// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Aff = require("../React.Basic.Hooks.Aff/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var UsePrev = function (x) {
    return x;
};
var newtypeUsePrev = {
    Coercible0: function () {
        return undefined;
    }
};
var usePrev = function (dictEq) {
    return function (value) {
        return React_Basic_Hooks_Internal.coerceHook()(React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useRef(Data_Maybe.Nothing.value))(function (ref) {
            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(dictEq)(value)(function __do() {
                React_Basic_Hooks.writeRef(ref)(new Data_Maybe.Just(value))();
                return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
            }))(function () {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Aff.useAff(dictEq)(value)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(React_Basic_Hooks.readRef(ref))))(function (current) {
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(Data_Maybe.fromMaybe(Data_Maybe.Nothing.value)(current));
                });
            });
        }));
    };
};
module.exports = {
    usePrev: usePrev,
    UsePrev: UsePrev,
    newtypeUsePrev: newtypeUsePrev
};
