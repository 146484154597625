// Generated by purs version 0.14.5
"use strict";
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var appComponent = function (name) {
    return function (render) {
        return function (v) {
            return React_Basic_Hooks.component(name)(render);
        };
    };
};
module.exports = {
    appComponent: appComponent
};
