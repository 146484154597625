// Generated by purs version 0.14.5
"use strict";
var App_State_TodosMapReducer = require("../App.State.TodosMapReducer/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var rootReducer = function (state) {
    return Data_Variant.match()()()({
        todosMap: function (action) {
            return {
                todosMap: App_State_TodosMapReducer.todosMapReducer(state.todosMap)(action)
            };
        }
    });
};
var rootInitialState = {
    todosMap: App_State_TodosMapReducer.todosMapInitialState
};
module.exports = {
    rootInitialState: rootInitialState,
    rootReducer: rootReducer
};
