// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkLayout = React_Basic_Hooks.component("Layout")(function (children) {
    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
        className: "flex flex-row justify-center",
        children: [ React_Basic_DOM_Generated.div()({
            className: "max-w-4xl w-full px-8",
            children: children
        }) ]
    }));
});
module.exports = {
    mkLayout: mkLayout
};
