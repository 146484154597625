// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Castable = require("../Untagged.Castable/index.js");
var textareaAutosize = function (dictCastable) {
    var $1 = React_Basic.element($foreign.textareaAutosize_);
    var $2 = Untagged_Castable.cast();
    return function ($3) {
        return $1($2($3));
    };
};
module.exports = {
    textareaAutosize: textareaAutosize,
    textareaAutosize_: $foreign.textareaAutosize_
};
