// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_ST_Class = require("../Control.Monad.ST.Class/index.js");
var Control_Monad_ST_Internal = require("../Control.Monad.ST.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Timer = require("../Effect.Timer/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var UseDebounce = function (x) {
    return x;
};
var newtypeUseDebounce = {
    Coercible0: function () {
        return undefined;
    }
};
var useDebounce = function (dictEq) {
    return function (timeout) {
        return function (value) {
            return React_Basic_Hooks_Internal.coerceHook()(React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(value))(function (v) {
                return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "value";
                    }
                })(dictEq))()({
                    reflectSymbol: function () {
                        return "timeout";
                    }
                })(Data_Eq.eqInt)))({
                    value: value,
                    timeout: timeout
                })(function __do() {
                    var ref = Control_Monad_ST_Class.liftST(Control_Monad_ST_Class.monadSTEffect)(Control_Monad_ST_Internal["new"](Data_Maybe.Nothing.value))();
                    var id = Effect_Timer.setTimeout(timeout)(v.value1(function (v1) {
                        return value;
                    }))();
                    Control_Monad_ST_Class.liftST(Control_Monad_ST_Class.monadSTEffect)(Control_Monad_ST_Internal.modify(function (v1) {
                        return new Data_Maybe.Just(id);
                    })(ref))();
                    return function __do() {
                        var maybeId = Control_Monad_ST_Class.liftST(Control_Monad_ST_Class.monadSTEffect)(Control_Monad_ST_Internal.read(ref))();
                        if (maybeId instanceof Data_Maybe.Just) {
                            return Effect_Timer.clearTimeout(maybeId.value0)();
                        };
                        return Data_Unit.unit;
                    };
                }))(function () {
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(v.value0);
                });
            }));
        };
    };
};
module.exports = {
    useDebounce: useDebounce,
    UseDebounce: UseDebounce,
    newtypeUseDebounce: newtypeUseDebounce
};
