// Generated by purs version 0.14.5
"use strict";
var App_Components_Todo = require("../App.Components.Todo/index.js");
var App_Routes_Helpers = require("../App.Routes.Helpers/index.js");
var App_State_Helpers = require("../App.State.Helpers/index.js");
var App_State_Selectors = require("../App.State.Selectors/index.js");
var App_State_TodosMapReducer = require("../App.State.TodosMapReducer/index.js");
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Aff = require("../React.Basic.Hooks.Aff/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkConnectedTodo = Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (v) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect))(App_Components_Todo.mkTodo))(function (todo) {
        return AppComponent.appComponent("ConnectedTodo")(function (v1) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_State_Helpers.useSelector(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "todosMap";
                }
            })(Data_Map_Internal.eqMap(Data_Eq.eqString)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "text";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "parent";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "note";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "children";
                }
            })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "checked";
                }
            })(Data_Eq.eqBoolean))))))(v.store.stateContext)(App_State_Selectors.todosMapSelector))(function (todosMapState) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(v.store.dispatchContext))(function (dispatch) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_Routes_Helpers.useRouterContext(v.router.routerContext))(function (v2) {
                        var maybeTodo = Data_Map_Internal.lookup(Data_Ord.ordString)(v1.id)(todosMapState);
                        var handleUpdate = function (updatedTodo) {
                            return dispatch(App_State_TodosMapReducer.updateTodo(updatedTodo.id)(updatedTodo));
                        };
                        var handleOpen = function (todoToOpen) {
                            return App_Routes_Helpers.navigateTo(v2.nav)("/" + todoToOpen.id);
                        };
                        var handleDelete = function (todoToDelete) {
                            return function __do() {
                                dispatch(App_State_TodosMapReducer.deleteTodo(todoToDelete))();
                                Effect_Aff.launchAff_(v["todosStorage"]["delete"](todoToDelete.id))();
                                return Data_Unit.unit;
                            };
                        };
                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useMemo(Data_Eq.eqUnit)(Data_Unit.unit)(function (v3) {
                            return handleUpdate;
                        }))(function (memoizedHandleUpdate) {
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useMemo(Data_Eq.eqUnit)(Data_Unit.unit)(function (v3) {
                                return handleOpen;
                            }))(function (memoizedHandleOpen) {
                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useMemo(Data_Eq.eqUnit)(Data_Unit.unit)(function (v3) {
                                    return handleDelete;
                                }))(function (memoizedHandleDelete) {
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(Data_Functor.map(React_Basic_Hooks_Internal.functorRender)(Data_Maybe.isJust)(React_Basic_Hooks_Aff.useAff(Data_Eq.eqString)(v1.id)(Control_Bind.bind(Effect_Aff.bindAff)(v.todosStorage.retrieve(v1.id))(function (eitherRetrievedTodo) {
                                        if (eitherRetrievedTodo instanceof Data_Either.Right && eitherRetrievedTodo.value0 instanceof Data_Maybe.Just) {
                                            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(dispatch(App_State_TodosMapReducer.loadTodo(eitherRetrievedTodo.value0.value0)));
                                        };
                                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                                    }))))(function (retrievedTodo) {
                                        return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Aff.useAff(Data_Tuple.eqTuple(Data_Eq.eqBoolean)(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                                            reflectSymbol: function () {
                                                return "text";
                                            }
                                        })(Data_Eq.eqString))()({
                                            reflectSymbol: function () {
                                                return "parent";
                                            }
                                        })(Data_Eq.eqString))()({
                                            reflectSymbol: function () {
                                                return "note";
                                            }
                                        })(Data_Eq.eqString))()({
                                            reflectSymbol: function () {
                                                return "id";
                                            }
                                        })(Data_Eq.eqString))()({
                                            reflectSymbol: function () {
                                                return "children";
                                            }
                                        })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                                            reflectSymbol: function () {
                                                return "checked";
                                            }
                                        })(Data_Eq.eqBoolean)))))(new Data_Tuple.Tuple(retrievedTodo, maybeTodo))((function () {
                                            var v3 = new Data_Tuple.Tuple(retrievedTodo, maybeTodo);
                                            if (v3.value0 && v3.value1 instanceof Data_Maybe.Just) {
                                                return v.todosStorage.store(v3.value1.value0.id)(v3.value1.value0);
                                            };
                                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Right(Data_Unit.unit));
                                        })()))(function () {
                                            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(Data_Maybe.fromMaybe(React_Basic_DOM_Generated.div_([  ]))(Data_Functor.map(Data_Maybe.functorMaybe)(function (t) {
                                                return React_Basic.element(todo)({
                                                    todo: t,
                                                    onChange: memoizedHandleUpdate,
                                                    onOpen: memoizedHandleOpen,
                                                    onDelete: memoizedHandleDelete
                                                });
                                            })(maybeTodo)));
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
});
module.exports = {
    mkConnectedTodo: mkConnectedTodo
};
