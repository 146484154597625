// Generated by purs version 0.14.5
"use strict";
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_UUID = require("../Data.UUID/index.js");
var rootTodoId = "__root__";
var mkTodo = function (parentId) {
    return function (id) {
        return function (text) {
            return function (checked) {
                return {
                    id: id,
                    text: text,
                    note: "",
                    checked: checked,
                    parent: parentId,
                    children: Data_List_Types.Nil.value
                };
            };
        };
    };
};
var rootTodo = mkTodo(rootTodoId)(rootTodoId)("")(false);
var rootTodoTuple = new Data_Tuple.Tuple(rootTodoId, rootTodo);
var isRootTodo = function (todo) {
    return todo.id === todo.parent;
};
var genUniqTodo = function (parentId) {
    return function (text) {
        return function (checked) {
            return function __do() {
                var uuid = Data_UUID.genUUID();
                return mkTodo(parentId)(Data_UUID.toString(uuid))(text)(checked);
            };
        };
    };
};
module.exports = {
    mkTodo: mkTodo,
    genUniqTodo: genUniqTodo,
    rootTodoId: rootTodoId,
    rootTodo: rootTodo,
    rootTodoTuple: rootTodoTuple,
    isRootTodo: isRootTodo
};
