// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkAddTodoInput = React_Basic_Hooks.memo(React_Basic_Hooks.reactComponent()()()("AddTodoInput")(function (v) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(""))(function (v1) {
        var handleChange = React_Basic_Events.handler(React_Basic_DOM_Events.targetValue)(function (maybeValue) {
            if (maybeValue instanceof Data_Maybe.Just) {
                return v1.value1(function (v3) {
                    return maybeValue.value0;
                });
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        });
        var handleAdd = React_Basic_Events.handler(React_Basic_Events.merge()(React_Basic_Events.mergeCons({
            reflectSymbol: function () {
                return "key";
            }
        })()()()()(React_Basic_Events.mergeCons({
            reflectSymbol: function () {
                return "targetValue";
            }
        })()()()()(React_Basic_Events.mergeNil)))({
            key: React_Basic_DOM_Events.key,
            targetValue: React_Basic_DOM_Events.targetValue
        }))(function (event) {
            if (event.key instanceof Data_Maybe.Just && (event.key.value0 === "Enter" && event.targetValue instanceof Data_Maybe.Just)) {
                return function __do() {
                    v.onAdd(event.targetValue.value0)();
                    v1.value1(function (v3) {
                        return "";
                    })();
                    return Data_Unit.unit;
                };
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        });
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.input()({
            type: "text",
            className: "bg-gray-100 py-3 px-5 rounded-lg w-full outline-black placeholder:text-gray-300",
            placeholder: "New todo...",
            autoFocus: true,
            value: v1.value0,
            onChange: handleChange,
            onKeyUp: handleAdd
        }));
    });
}));
module.exports = {
    mkAddTodoInput: mkAddTodoInput
};
