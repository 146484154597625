// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkCheckbox = React_Basic_Hooks.component("Checkbox")(function (v) {
    var handleChange = React_Basic_DOM_Events.capture_(v.onChange(!v.checked));
    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
        if (v.checked) {
            return React_Basic_DOM_Generated.div()({
                className: "rounded-md block bg-black w-5 h-5 text-white cursor-pointer",
                role: "checkbox",
                tabIndex: 0,
                "_aria": Foreign_Object.singleton("checked")("true"),
                onClick: handleChange,
                children: [ React_Basic_DOM_Generated.i()({
                    className: "bg-black w-full h-full rounded-md gg-check gg-check-fix",
                    children: [  ]
                }) ]
            });
        };
        if (!v.checked) {
            return React_Basic_DOM_Generated.div()({
                className: "rounded-md block bg-gray-300 w-5 h-5 cursor-pointer",
                role: "checkbox",
                tabIndex: 0,
                "_aria": Foreign_Object.singleton("checked")("false"),
                onClick: handleChange,
                children: [  ]
            });
        };
        throw new Error("Failed pattern match at App.Components.Checkbox (line 25, column 10 - line 48, column 12): " + [ v.checked.constructor.name ]);
    })());
});
module.exports = {
    mkCheckbox: mkCheckbox
};
