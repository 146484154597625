// Generated by purs version 0.14.5
"use strict";
var App_Components_Link = require("../App.Components.Link/index.js");
var App_State_Helpers = require("../App.State.Helpers/index.js");
var App_State_Selectors = require("../App.State.Selectors/index.js");
var App_State_Todo = require("../App.State.Todo/index.js");
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var todoTextMaxLength = 20;
var sanitizeTodoText = function (str) {
    if (Data_String_CodePoints.length(str) > todoTextMaxLength) {
        return Data_String_CodePoints.take(todoTextMaxLength)(str) + "...";
    };
    if (Data_Boolean.otherwise) {
        return str;
    };
    throw new Error("Failed pattern match at App.Components.Breadcrumb (line 116, column 1 - line 116, column 37): " + [ str.constructor.name ]);
};
var generatePartialRootPath = function (todosMap) {
    return function (currentTodo) {
        var generatePartialRootPath$prime = function ($copy_c) {
            return function ($copy_a) {
                var $tco_var_c = $copy_c;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(c, a) {
                    if (c.id === App_State_Todo.rootTodoId) {
                        $tco_done = true;
                        return Data_List.snoc(a)(c);
                    };
                    if (Data_Boolean.otherwise) {
                        var v = Data_Map_Internal.lookup(Data_Ord.ordString)(c.parent)(todosMap);
                        if (v instanceof Data_Maybe.Just) {
                            $tco_var_c = v.value0;
                            $copy_a = Data_List.snoc(a)(c);
                            return;
                        };
                        $tco_done = true;
                        return Data_List.snoc(Data_List.snoc(a)(c))(App_State_Todo.rootTodo);
                    };
                    throw new Error("Failed pattern match at App.Components.Breadcrumb (line 107, column 3 - line 107, column 61): " + [ c.constructor.name, a.constructor.name ]);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_c, $copy_a);
                };
                return $tco_result;
            };
        };
        return generatePartialRootPath$prime(currentTodo)(Data_List_Types.Nil.value);
    };
};
var mkBreadcrumb = Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (v) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(App_Components_Link.mkLink)(function (link) {
        var renderTodo = function (todo) {
            return link({
                route: "/" + todo.id,
                children: [ React_Basic_DOM_Generated.span()({
                    className: "text-black flex flex-row gap-1",
                    children: [ React_Basic_DOM_Generated.i()({
                        className: "text-gray-200 gg-chevron-double-right gg-small",
                        children: [  ]
                    }), React_Basic_DOM.text(sanitizeTodoText(todo.text)) ]
                }) ]
            });
        };
        var renderRoot = link({
            route: "/",
            children: [ React_Basic_DOM_Generated.span()({
                className: "text-black flex flex-row",
                children: [ React_Basic_DOM_Generated.i()({
                    className: "gg-home gg-small",
                    children: [  ]
                }) ]
            }) ]
        });
        var renderAggregated = function (todo) {
            return link({
                route: "/" + todo.id,
                children: [ React_Basic_DOM_Generated.span()({
                    className: "text-black flex flex-row gap-1",
                    children: [ React_Basic_DOM_Generated.i()({
                        className: "text-gray-200 gg-chevron-double-right gg-small",
                        children: [  ]
                    }), React_Basic_DOM.text("[...]") ]
                }) ]
            });
        };
        var renderRootPath = function (path) {
            if (path instanceof Data_List_Types.Cons && path.value1 instanceof Data_List_Types.Nil) {
                return [ renderRoot ];
            };
            if (path instanceof Data_List_Types.Cons && (path.value1 instanceof Data_List_Types.Cons && path.value1.value1 instanceof Data_List_Types.Nil)) {
                return [ renderRoot, renderTodo(path.value0) ];
            };
            if (path instanceof Data_List_Types.Cons && path.value1 instanceof Data_List_Types.Cons) {
                return [ renderRoot, renderAggregated(path.value1.value0), renderTodo(path.value0) ];
            };
            return [  ];
        };
        return AppComponent.appComponent("Link")(function (v1) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(App_State_Helpers.useSelector(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "todosMap";
                }
            })(Data_Map_Internal.eqMap(Data_Eq.eqString)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "text";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "parent";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "note";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "children";
                }
            })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "checked";
                }
            })(Data_Eq.eqBoolean))))))(v.store.stateContext)(App_State_Selectors.todosMapSelector))(function (todosMapState) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useMemo(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "todosMapState";
                    }
                })(Data_Map_Internal.eqMap(Data_Eq.eqString)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "text";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "parent";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "note";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "children";
                    }
                })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "checked";
                    }
                })(Data_Eq.eqBoolean)))))()({
                    reflectSymbol: function () {
                        return "currentTodo";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "text";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "parent";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "note";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "children";
                    }
                })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "checked";
                    }
                })(Data_Eq.eqBoolean)))))({
                    todosMapState: todosMapState,
                    currentTodo: v1.currentTodo
                })(function (v2) {
                    return generatePartialRootPath(todosMapState)(v1.currentTodo);
                }))(function (partialRootPath) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useMemo(Data_List_Types.eqList(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                        reflectSymbol: function () {
                            return "text";
                        }
                    })(Data_Eq.eqString))()({
                        reflectSymbol: function () {
                            return "parent";
                        }
                    })(Data_Eq.eqString))()({
                        reflectSymbol: function () {
                            return "note";
                        }
                    })(Data_Eq.eqString))()({
                        reflectSymbol: function () {
                            return "id";
                        }
                    })(Data_Eq.eqString))()({
                        reflectSymbol: function () {
                            return "children";
                        }
                    })(Data_List_Types.eqList(Data_Eq.eqString)))()({
                        reflectSymbol: function () {
                            return "checked";
                        }
                    })(Data_Eq.eqBoolean))))(partialRootPath)(function (v2) {
                        return renderRootPath(partialRootPath);
                    }))(function (breadcrumbItems) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                            className: "flex flex-row items-center gap-1",
                            children: breadcrumbItems
                        }));
                    });
                });
            });
        });
    });
});
module.exports = {
    todoTextMaxLength: todoTextMaxLength,
    mkBreadcrumb: mkBreadcrumb,
    generatePartialRootPath: generatePartialRootPath,
    sanitizeTodoText: sanitizeTodoText
};
