// Generated by purs version 0.14.5
"use strict";
var App_Components_ConnectedTodo = require("../App.Components.ConnectedTodo/index.js");
var AppComponent = require("../AppComponent/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkTodosList = Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(App_Components_ConnectedTodo.mkConnectedTodo)(function (connectedTodo) {
    return AppComponent.appComponent("TodosList")(function (v) {
        var maybeTodosArr = Data_Array_NonEmpty.fromFoldable(Data_List_Types.foldableList)(Data_Functor.map(Data_List_Types.functorList)(function (id) {
            return React_Basic_DOM_Generated.li()({
                key: id,
                className: "mb-4",
                children: [ connectedTodo({
                    id: id
                }) ]
            });
        })(v.todos));
        var todosArr = Data_Maybe.fromMaybe([  ])(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Array_NonEmpty.toArray)(maybeTodosArr));
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.ul_(todosArr));
    });
});
module.exports = {
    mkTodosList: mkTodosList
};
