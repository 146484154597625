// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Routing_Match = require("../Routing.Match/index.js");
var RootTodos = (function () {
    function RootTodos() {

    };
    RootTodos.value = new RootTodos();
    return RootTodos;
})();
var ChildrenTodos = (function () {
    function ChildrenTodos(value0) {
        this.value0 = value0;
    };
    ChildrenTodos.create = function (value0) {
        return new ChildrenTodos(value0);
    };
    return ChildrenTodos;
})();
var mkAppRoute = function (maybeRootDir) {
    var routes = Control_Apply.applyFirst(Routing_Match.matchApply)(Control_Apply.applySecond(Routing_Match.matchApply)(Data_Maybe.fromMaybe(Routing_Match.root)(Data_Functor.map(Data_Maybe.functorMaybe)(function (path) {
        return Control_Apply.applySecond(Routing_Match.matchApply)(Routing_Match.root)(Routing_Match.lit(path));
    })(maybeRootDir)))(Data_Foldable.oneOf(Data_Foldable.foldableArray)(Routing_Match.matchPlus)([ Data_Functor.map(Routing_Match.matchFunctor)(ChildrenTodos.create)(Routing_Match.str), Control_Applicative.pure(Routing_Match.matchApplicative)(RootTodos.value) ])))(Routing_Match.end);
    return Data_Foldable.oneOf(Data_Foldable.foldableArray)(Routing_Match.matchPlus)([ Data_Functor.map(Routing_Match.matchFunctor)(Data_Maybe.Just.create)(routes), Control_Applicative.pure(Routing_Match.matchApplicative)(Data_Maybe.Nothing.value) ]);
};
var genericAppRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return RootTodos.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new ChildrenTodos(x.value0);
        };
        throw new Error("Failed pattern match at App.Routes (line 20, column 1 - line 20, column 54): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof RootTodos) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof ChildrenTodos) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at App.Routes (line 20, column 1 - line 20, column 54): " + [ x.constructor.name ]);
    }
};
var showAppRoute = {
    show: Data_Show_Generic.genericShow(genericAppRoute)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
        reflectSymbol: function () {
            return "RootTodos";
        }
    }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showString))({
        reflectSymbol: function () {
            return "ChildrenTodos";
        }
    })))
};
var eqAppRoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof RootTodos && y instanceof RootTodos) {
                return true;
            };
            if (x instanceof ChildrenTodos && y instanceof ChildrenTodos) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
module.exports = {
    RootTodos: RootTodos,
    ChildrenTodos: ChildrenTodos,
    mkAppRoute: mkAppRoute,
    genericAppRoute: genericAppRoute,
    eqAppRoute: eqAppRoute,
    showAppRoute: showAppRoute
};
